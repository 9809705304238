import pl from '@/lang/pl.json';
import ua from '@/lang/ua.json';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'pl',
  messages: {
    pl,
    ua,
  },
}));
